<template>
<svg width="103" height="35" viewBox="0 0 103 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_203_2753)">
      <path d="M94.0212 0.000110669H8.13968C7.82663 0.000110669 7.51734 0.000110669 7.20512 0.00181327C6.94376 0.00351588 6.68449 0.00846195 6.42062 0.0126248C5.84738 0.0193516 5.27548 0.0696419 4.70991 0.163058C4.14512 0.258509 3.59803 0.43846 3.08714 0.696825C2.57687 0.957376 2.11062 1.29594 1.70531 1.70022C1.29787 2.10337 0.958238 2.56936 0.699473 3.08026C0.439993 3.59015 0.260069 4.13654 0.165915 4.70056C0.0708658 5.26387 0.0197189 5.83368 0.0129334 6.40487C0.00501117 6.66588 0.00417456 6.92775 0 7.1888V26.866C0.00417456 27.1304 0.00501117 27.3864 0.0129334 27.6508C0.019721 28.222 0.0708679 28.7918 0.165915 29.3551C0.259809 29.9194 0.439744 30.4661 0.699473 30.9762C0.958121 31.4855 1.2978 31.9495 1.70531 32.3505C2.10909 32.7565 2.57563 33.0953 3.08714 33.3539C3.59803 33.6129 4.14506 33.794 4.70991 33.8909C5.27558 33.9836 5.84741 34.0339 6.42062 34.0414C6.68449 34.0472 6.94376 34.0505 7.20512 34.0505C7.51733 34.0522 7.82665 34.0522 8.13968 34.0522H94.0212C94.328 34.0522 94.6398 34.0522 94.9466 34.0505C95.2067 34.0505 95.4735 34.0472 95.7336 34.0414C96.3058 34.0343 96.8765 33.984 97.441 33.8909C98.0077 33.7933 98.5567 33.6123 99.07 33.3539C99.5811 33.0952 100.047 32.7564 100.451 32.3505C100.857 31.948 101.197 31.4842 101.459 30.9762C101.717 30.4657 101.895 29.9191 101.988 29.3551C102.083 28.7917 102.136 28.2221 102.146 27.6508C102.15 27.3864 102.15 27.1304 102.15 26.866C102.156 26.5567 102.156 26.2492 102.156 25.9349V8.11824C102.156 7.80649 102.156 7.49722 102.15 7.1888C102.15 6.92775 102.15 6.66588 102.146 6.40483C102.136 5.8336 102.083 5.26392 101.988 4.70053C101.895 4.13683 101.717 3.59048 101.459 3.08023C100.932 2.05617 100.097 1.2226 99.07 0.696748C98.5567 0.439014 98.0076 0.259111 97.441 0.162982C96.8766 0.0691537 96.3058 0.0188459 95.7336 0.0125056C95.4735 0.00835128 95.2067 0.00336264 94.9466 0.00170261C94.6398 0 94.328 0 94.0212 0V0.000110669Z" fill="black"/>
      <path d="M7.2101 33.3074C6.95 33.3074 6.69618 33.3041 6.43811 33.2983C5.90352 33.2914 5.37017 33.245 4.84244 33.1595C4.35037 33.075 3.87368 32.9178 3.4281 32.6931C2.9866 32.4702 2.58392 32.178 2.23549 31.8276C1.88201 31.4814 1.58772 31.0798 1.36429 30.6388C1.13839 30.1949 0.982057 29.7191 0.900732 29.228C0.812907 28.7003 0.765391 28.1667 0.758592 27.6318C0.753179 27.4523 0.746094 26.8545 0.746094 26.8545V7.18892C0.746094 7.18892 0.75364 6.60032 0.758635 6.42739C0.765145 5.89335 0.812385 5.36058 0.899946 4.83369C0.981422 4.34123 1.13788 3.86408 1.36389 3.41874C1.58651 2.97804 1.87916 2.57615 2.23051 2.22867C2.58147 1.87788 2.98543 1.58416 3.42768 1.35823C3.87223 1.13424 4.34802 0.978146 4.8391 0.895176C5.36856 0.808825 5.90376 0.76214 6.44021 0.755512L7.21052 0.745117H94.9432L95.7227 0.755929C96.2543 0.762228 96.7847 0.808497 97.3092 0.894342C97.8053 0.978353 98.286 1.13554 98.7357 1.36073C99.6217 1.81604 100.343 2.5364 100.797 3.42082C101.02 3.86308 101.174 4.33634 101.254 4.82455C101.343 5.35576 101.392 5.89272 101.403 6.43114C101.405 6.67223 101.405 6.93119 101.405 7.18892C101.412 7.50815 101.412 7.81201 101.412 8.11836V25.935C101.412 26.2443 101.412 26.5461 101.405 26.8503C101.405 27.1272 101.405 27.3808 101.402 27.6418C101.392 28.1706 101.343 28.6979 101.256 29.2197C101.176 29.7143 101.021 30.1938 100.795 30.6413C100.57 31.0776 100.277 31.4757 99.9279 31.821C99.5791 32.1732 99.1757 32.4672 98.7332 32.6914C98.2848 32.9179 97.8048 33.0757 97.3092 33.1595C96.7816 33.2454 96.2482 33.2918 95.7135 33.2983C95.4635 33.3041 95.2017 33.3074 94.9474 33.3074L94.022 33.3091L7.2101 33.3074Z" fill="white"/>
      <path d="M21.3394 16.9327C21.349 16.1938 21.5458 15.4693 21.9116 14.8267C22.2774 14.184 22.8002 13.6441 23.4315 13.2571C23.0305 12.6859 22.5014 12.2159 21.8863 11.8843C21.2713 11.5527 20.5872 11.3687 19.8883 11.3469C18.3976 11.1909 16.9524 12.2365 16.1927 12.2365C15.4183 12.2365 14.2487 11.3624 12.9892 11.3883C12.1746 11.4145 11.3806 11.6507 10.6847 12.074C9.98885 12.4972 9.41476 13.0929 9.01841 13.8032C7.30154 16.7674 8.58217 21.1238 10.2268 23.5199C11.0497 24.6932 12.0113 26.0038 13.2697 25.9573C14.5011 25.9064 14.961 25.1743 16.4473 25.1743C17.9199 25.1743 18.3513 25.9573 19.6352 25.9278C20.9565 25.9064 21.789 24.7493 22.583 23.5649C23.1743 22.7289 23.6292 21.8049 23.931 20.8272C23.1634 20.5034 22.5083 19.9615 22.0474 19.2689C21.5866 18.5764 21.3403 17.7639 21.3394 16.9327Z" fill="black"/>
      <path d="M18.9153 9.7708C19.6358 8.90837 19.9907 7.79985 19.9048 6.68066C18.8041 6.79594 17.7874 7.32051 17.0572 8.14986C16.7002 8.55501 16.4268 9.02636 16.2526 9.53696C16.0784 10.0476 16.0068 10.5874 16.0419 11.1256C16.5924 11.1312 17.1371 11.0122 17.6348 10.7776C18.1325 10.5429 18.5703 10.1987 18.9153 9.7708Z" fill="black"/>
      <path d="M36.1115 23.1047H32.0706L31.1002 25.9621H29.3887L33.2161 15.3906H34.9944L38.8218 25.9621H37.0811L36.1115 23.1047ZM32.4891 21.7862H35.6922L34.1132 17.1489H34.069L32.4891 21.7862Z" fill="black"/>
      <path d="M47.0892 22.1091C47.0892 24.5042 45.8036 26.043 43.8637 26.043C43.3722 26.0686 42.8835 25.9558 42.4534 25.7173C42.0233 25.4788 41.6693 25.1244 41.4318 24.6945H41.3951V28.5121H39.8086V18.2549H41.3442V19.5369H41.3734C41.6218 19.1091 41.9818 18.7565 42.4152 18.5166C42.8486 18.2766 43.339 18.1583 43.8345 18.1743C45.7961 18.1743 47.0892 19.7206 47.0892 22.1091ZM45.4585 22.1091C45.4585 20.5486 44.6498 19.5227 43.416 19.5227C42.2038 19.5227 41.3884 20.5702 41.3884 22.1091C41.3884 23.662 42.2038 24.702 43.416 24.702C44.6498 24.702 45.4585 23.6836 45.4585 22.1091H45.4585Z" fill="black"/>
      <path d="M55.5954 22.1091C55.5954 24.5042 54.3095 26.043 52.3695 26.043C51.8781 26.0686 51.3893 25.9558 50.9593 25.7173C50.5292 25.4788 50.1751 25.1244 49.9377 24.6945H49.901V28.5121H48.3145V18.2549H49.8501V19.5369H49.8793C50.1277 19.1091 50.4877 18.7565 50.921 18.5166C51.3544 18.2766 51.8449 18.1583 52.3403 18.1743C54.302 18.1743 55.5954 19.7206 55.5954 22.1091ZM53.9644 22.1091C53.9644 20.5486 53.1557 19.5227 51.9218 19.5227C50.7097 19.5227 49.8943 20.5702 49.8943 22.1091C49.8943 23.662 50.7097 24.702 51.9218 24.702C53.1557 24.702 53.9644 23.6836 53.9644 22.1091Z" fill="black"/>
      <path d="M61.2178 23.0171C61.3354 24.0654 62.3566 24.7538 63.7522 24.7538C65.0894 24.7538 66.0515 24.0654 66.0515 23.1202C66.0515 22.2996 65.4713 21.8083 64.0974 21.4716L62.7234 21.1415C60.7768 20.6727 59.8731 19.7648 59.8731 18.2917C59.8731 16.4677 61.4671 15.2148 63.7297 15.2148C65.9706 15.2148 67.5063 16.4677 67.558 18.2917H65.9565C65.8606 17.2367 64.986 16.5999 63.708 16.5999C62.43 16.5999 61.5554 17.2442 61.5554 18.1819C61.5554 18.9293 62.114 19.3691 63.4804 19.7058L64.6484 19.9918C66.8235 20.5047 67.7264 21.376 67.7264 22.9223C67.7264 24.9 66.1474 26.1388 63.6346 26.1388C61.2836 26.1388 59.6963 24.9291 59.5938 23.017L61.2178 23.0171Z" fill="black"/>
      <path d="M71.1514 16.4316V18.2556H72.6212V19.5085H71.1514V23.7575C71.1514 24.4176 71.4457 24.7252 72.0918 24.7252C72.2663 24.7222 72.4405 24.7099 72.6137 24.6886V25.9339C72.3232 25.9881 72.0279 26.0126 71.7325 26.0071C70.1677 26.0071 69.5574 25.421 69.5574 23.9262V19.5085H68.4336V18.2556H69.5574V16.4316H71.1514Z" fill="black"/>
      <path d="M73.4707 22.1091C73.4707 19.684 74.903 18.1602 77.1364 18.1602C79.3773 18.1602 80.8029 19.684 80.8029 22.1091C80.8029 24.5408 79.3849 26.058 77.1364 26.058C74.8888 26.058 73.4707 24.5408 73.4707 22.1091ZM79.1864 22.1091C79.1864 20.4455 78.4219 19.4637 77.1364 19.4637C75.8509 19.4637 75.0864 20.453 75.0864 22.1091C75.0864 23.7792 75.8509 24.7536 77.1364 24.7536C78.4219 24.7536 79.1864 23.7792 79.1864 22.1091H79.1864Z" fill="black"/>
      <path d="M82.1113 18.2558H83.6245V19.5677H83.6612C83.7636 19.158 84.0043 18.7959 84.3428 18.5423C84.6814 18.2887 85.0972 18.1591 85.5203 18.1752C85.7031 18.1745 85.8854 18.1943 86.0639 18.2342V19.714C85.833 19.6437 85.5923 19.6114 85.351 19.6184C85.1205 19.6091 84.8908 19.6496 84.6775 19.7372C84.4642 19.8248 84.2724 19.9573 84.1154 20.1258C83.9583 20.2943 83.8397 20.4946 83.7676 20.7131C83.6955 20.9316 83.6717 21.1631 83.6978 21.3917V25.9633H82.1113L82.1113 18.2558Z" fill="black"/>
      <path d="M93.3783 23.6986C93.1649 25.0978 91.7985 26.058 90.0503 26.058C87.8018 26.058 86.4062 24.5557 86.4062 22.1456C86.4062 19.7281 87.8093 18.1602 89.9836 18.1602C92.122 18.1602 93.4667 19.625 93.4667 21.9619V22.504H88.0078V22.5996C87.9826 22.8832 88.0182 23.169 88.1122 23.4378C88.2063 23.7067 88.3566 23.9526 88.5533 24.159C88.7499 24.3655 88.9884 24.5279 89.2528 24.6354C89.5173 24.743 89.8016 24.7932 90.087 24.7827C90.4619 24.8177 90.8381 24.7311 91.1597 24.5358C91.4813 24.3405 91.7311 24.0469 91.8719 23.6986L93.3783 23.6986ZM88.0153 21.3983H91.8794C91.8936 21.1432 91.8548 20.888 91.7655 20.6486C91.6761 20.4092 91.5381 20.1908 91.3601 20.0071C91.1821 19.8234 90.9679 19.6784 90.731 19.5812C90.4942 19.484 90.2397 19.4366 89.9836 19.4421C89.7252 19.4406 89.4691 19.4901 89.2301 19.5877C88.991 19.6854 88.7737 19.8293 88.5907 20.0112C88.4077 20.1931 88.2626 20.4093 88.1639 20.6474C88.0651 20.8854 88.0146 21.1406 88.0153 21.3983V21.3983Z" fill="black"/>
      <path d="M32.2911 7.43252C32.6237 7.40872 32.9575 7.45883 33.2683 7.57923C33.5792 7.69963 33.8593 7.88733 34.0886 8.12878C34.3179 8.37023 34.4906 8.65942 34.5942 8.97548C34.6979 9.29154 34.7299 9.62661 34.688 9.9565C34.688 11.5793 33.8084 12.5121 32.2911 12.5121H30.4512V7.43252H32.2911ZM31.2423 11.7937H32.2027C32.4404 11.8079 32.6783 11.7691 32.899 11.68C33.1197 11.5909 33.3177 11.4539 33.4786 11.2789C33.6395 11.1039 33.7593 10.8953 33.8292 10.6684C33.8991 10.4414 33.9173 10.2017 33.8826 9.96685C33.9148 9.73288 33.8947 9.49471 33.8237 9.2694C33.7528 9.0441 33.6327 8.83723 33.4722 8.66362C33.3116 8.49001 33.1146 8.35397 32.8951 8.26523C32.6756 8.17649 32.4392 8.13726 32.2027 8.15035H31.2423V11.7937Z" fill="black"/>
      <path d="M35.5808 10.5944C35.5567 10.3425 35.5856 10.0883 35.6657 9.84822C35.7458 9.60809 35.8753 9.38731 36.046 9.20004C36.2167 9.01277 36.4248 8.86314 36.6569 8.76075C36.8889 8.65836 37.1399 8.60547 37.3937 8.60547C37.6475 8.60547 37.8984 8.65836 38.1305 8.76075C38.3626 8.86314 38.5707 9.01277 38.7413 9.20004C38.912 9.38731 39.0416 9.60809 39.1217 9.84822C39.2018 10.0883 39.2307 10.3425 39.2065 10.5944C39.2312 10.8466 39.2026 11.1011 39.1227 11.3416C39.0428 11.5822 38.9133 11.8033 38.7426 11.991C38.5719 12.1786 38.3637 12.3286 38.1313 12.4312C37.899 12.5338 37.6478 12.5868 37.3937 12.5868C37.1396 12.5868 36.8883 12.5338 36.656 12.4312C36.4237 12.3286 36.2155 12.1786 36.0448 11.991C35.874 11.8033 35.7446 11.5822 35.6647 11.3416C35.5848 11.1011 35.5562 10.8466 35.5808 10.5944ZM38.4262 10.5944C38.4262 9.7635 38.0519 9.27758 37.3949 9.27758C36.7355 9.27758 36.3645 9.7635 36.3645 10.5944C36.3645 11.432 36.7355 11.9142 37.3949 11.9142C38.0519 11.9142 38.4262 11.4287 38.4262 10.5944H38.4262Z" fill="black"/>
      <path d="M44.0277 12.513H43.2407L42.4462 9.68977H42.3861L41.595 12.513H40.8155L39.7559 8.67969H40.5254L41.214 11.6048H41.2707L42.061 8.67969H42.7888L43.5791 11.6048H43.6392L44.3244 8.67969H45.0831L44.0277 12.513Z" fill="black"/>
      <path d="M45.9746 8.67933H46.7049V9.28829H46.7616C46.8578 9.06958 47.02 8.88623 47.2256 8.76381C47.4312 8.64138 47.6701 8.58599 47.9088 8.60534C48.0958 8.59131 48.2836 8.61944 48.4583 8.68762C48.6329 8.75581 48.79 8.86231 48.9178 8.99924C49.0455 9.13616 49.1408 9.3 49.1965 9.47863C49.2521 9.65725 49.2668 9.84607 49.2393 10.0311V12.5126H48.4807V10.2211C48.4807 9.60505 48.2122 9.29869 47.6512 9.29869C47.5242 9.29279 47.3974 9.31434 47.2795 9.36187C47.1617 9.4094 47.0555 9.48178 46.9683 9.57405C46.8811 9.66632 46.815 9.77629 46.7744 9.89644C46.7339 10.0166 46.7198 10.1441 46.7333 10.2701V12.5127H45.9746L45.9746 8.67933Z" fill="black"/>
      <path d="M50.4473 7.18262H51.2059V12.5124H50.4473V7.18262Z" fill="black"/>
      <path d="M52.2605 10.5945C52.2364 10.3426 52.2653 10.0884 52.3454 9.84825C52.4255 9.60812 52.5551 9.38733 52.7258 9.20005C52.8965 9.01278 53.1046 8.86315 53.3367 8.76075C53.5688 8.65836 53.8198 8.60547 54.0736 8.60547C54.3274 8.60547 54.5783 8.65836 54.8104 8.76075C55.0425 8.86315 55.2506 9.01278 55.4213 9.20005C55.592 9.38733 55.7216 9.60812 55.8017 9.84825C55.8818 10.0884 55.9108 10.3426 55.8866 10.5945C55.9112 10.8467 55.8826 11.1012 55.8027 11.3417C55.7228 11.5822 55.5933 11.8034 55.4225 11.9911C55.2518 12.1787 55.0436 12.3286 54.8112 12.4312C54.5789 12.5338 54.3277 12.5868 54.0736 12.5868C53.8195 12.5868 53.5682 12.5338 53.3359 12.4312C53.1036 12.3286 52.8953 12.1787 52.7246 11.9911C52.5538 11.8034 52.4243 11.5822 52.3444 11.3417C52.2645 11.1012 52.2359 10.8467 52.2605 10.5945ZM55.1059 10.5945C55.1059 9.76357 54.7315 9.27765 54.0746 9.27765C53.4152 9.27765 53.0442 9.76357 53.0442 10.5945C53.0442 11.4321 53.4152 11.9143 54.0746 11.9143C54.7315 11.9143 55.1059 11.4288 55.1059 10.5945H55.1059Z" fill="black"/>
      <path d="M56.6855 11.4287C56.6855 10.7387 57.2008 10.3409 58.1153 10.2844L59.1566 10.2245V9.89364C59.1566 9.48877 58.8881 9.26015 58.3696 9.26015C57.9461 9.26015 57.6526 9.4152 57.5684 9.68622H56.8339C56.9115 9.02779 57.5326 8.60547 58.4046 8.60547C59.3683 8.60547 59.9119 9.0839 59.9119 9.89364V12.5128H59.1816V11.9741H59.1216C58.9997 12.1673 58.8287 12.3249 58.6258 12.4306C58.4229 12.5364 58.1956 12.5865 57.9669 12.576C57.8055 12.5927 57.6424 12.5755 57.4881 12.5256C57.3338 12.4756 57.1917 12.3939 57.071 12.2858C56.9503 12.1778 56.8536 12.0456 56.7872 11.898C56.7208 11.7504 56.6862 11.5905 56.6855 11.4287ZM59.1566 11.1012V10.7807L58.2179 10.8405C57.6885 10.8759 57.4484 11.0554 57.4484 11.3934C57.4484 11.7384 57.7485 11.9392 58.1612 11.9392C58.2821 11.9514 58.4043 11.9392 58.5204 11.9034C58.6365 11.8675 58.7442 11.8088 58.837 11.7306C58.9299 11.6524 59.0061 11.5564 59.061 11.4483C59.1158 11.3401 59.1484 11.2221 59.1566 11.1012Z" fill="black"/>
      <path d="M60.9082 10.5941C60.9082 9.3828 61.5326 8.61546 62.5039 8.61546C62.7441 8.60442 62.9826 8.6618 63.1913 8.7809C63.4 8.89999 63.5705 9.07587 63.6827 9.28799H63.7394V7.18262H64.498V12.5124H63.7711V11.9068H63.7111C63.5902 12.1174 63.4138 12.2911 63.201 12.409C62.9882 12.5269 62.7471 12.5845 62.5039 12.5756C61.526 12.5756 60.9082 11.8083 60.9082 10.5941ZM61.6919 10.5941C61.6919 11.4071 62.0762 11.8964 62.719 11.8964C63.3584 11.8964 63.7536 11.4001 63.7536 10.5974C63.7536 9.79847 63.3543 9.29509 62.719 9.29509C62.0804 9.29509 61.6919 9.78766 61.6919 10.5941H61.6919Z" fill="black"/>
      <path d="M67.6375 10.5944C67.6133 10.3425 67.6422 10.0883 67.7223 9.84822C67.8024 9.60809 67.932 9.38731 68.1027 9.20004C68.2734 9.01277 68.4814 8.86314 68.7135 8.76075C68.9456 8.65836 69.1965 8.60547 69.4503 8.60547C69.7041 8.60547 69.9551 8.65836 70.1871 8.76075C70.4192 8.86314 70.6273 9.01277 70.798 9.20004C70.9687 9.38731 71.0982 9.60809 71.1783 9.84822C71.2584 10.0883 71.2873 10.3425 71.2632 10.5944C71.2878 10.8466 71.2592 11.1011 71.1793 11.3416C71.0994 11.5822 70.97 11.8033 70.7992 11.991C70.6285 12.1786 70.4203 12.3286 70.188 12.4312C69.9557 12.5338 69.7044 12.5868 69.4503 12.5868C69.1962 12.5868 68.945 12.5338 68.7127 12.4312C68.4804 12.3286 68.2721 12.1786 68.1014 11.991C67.9307 11.8033 67.8012 11.5822 67.7213 11.3416C67.6414 11.1011 67.6129 10.8466 67.6375 10.5944ZM70.4828 10.5944C70.4828 9.7635 70.1085 9.27758 69.4516 9.27758C68.7921 9.27758 68.4212 9.7635 68.4212 10.5944C68.4212 11.432 68.7922 11.9142 69.4516 11.9142C70.1085 11.9142 70.4828 11.4287 70.4828 10.5944Z" fill="black"/>
      <path d="M72.2812 8.67933H73.0116V9.28829H73.0683C73.1644 9.06958 73.3266 8.88623 73.5323 8.76381C73.7379 8.64138 73.9767 8.58599 74.2154 8.60534C74.4024 8.59131 74.5902 8.61944 74.7649 8.68762C74.9396 8.75581 75.0966 8.86231 75.2244 8.99924C75.3522 9.13616 75.4474 9.3 75.5031 9.47863C75.5588 9.65725 75.5734 9.84607 75.5459 10.0311V12.5126H74.7873V10.2211C74.7873 9.60505 74.5189 9.29869 73.9578 9.29869C73.8308 9.29279 73.704 9.31434 73.5862 9.36187C73.4683 9.4094 73.3621 9.48178 73.275 9.57405C73.1878 9.66632 73.1216 9.77629 73.0811 9.89644C73.0405 10.0166 73.0264 10.1441 73.0399 10.2701V12.5127H72.2812V8.67933Z" fill="black"/>
      <path d="M79.8326 7.72461V8.69646H80.6655V9.33369H79.8326V11.3048C79.8326 11.7064 79.9985 11.8822 80.3762 11.8822C80.4729 11.8819 80.5695 11.8761 80.6655 11.8647V12.4949C80.5293 12.5192 80.3912 12.5321 80.2528 12.5335C79.4091 12.5335 79.0731 12.2376 79.0731 11.4985V9.33366H78.4629V8.69642H79.0731V7.72461H79.8326Z" fill="black"/>
      <path d="M81.7012 7.18262H82.4532V9.29508H82.5132C82.614 9.07432 82.7807 8.88994 82.9904 8.76705C83.2002 8.64417 83.4428 8.58876 83.6853 8.60839C83.8714 8.59829 84.0574 8.62927 84.2301 8.69911C84.4027 8.76895 84.5578 8.87594 84.6843 9.01243C84.8107 9.14893 84.9054 9.31157 84.9616 9.48876C85.0177 9.66595 85.034 9.85331 85.0092 10.0375V12.5124H84.2498V10.2241C84.2498 9.61182 83.9638 9.30172 83.4278 9.30172C83.2974 9.29106 83.1662 9.30893 83.0435 9.3541C82.9207 9.39926 82.8094 9.47063 82.7172 9.56319C82.625 9.65576 82.5542 9.7673 82.5097 9.89001C82.4653 10.0127 82.4483 10.1436 82.4599 10.2736V12.5124H81.7012L81.7012 7.18262Z" fill="black"/>
      <path d="M89.4325 11.4778C89.3294 11.8282 89.1061 12.1312 88.8016 12.334C88.497 12.5367 88.1308 12.6262 87.7668 12.5869C87.5135 12.5935 87.2618 12.5452 87.0292 12.4451C86.7965 12.3451 86.5885 12.1957 86.4196 12.0075C86.2506 11.8192 86.1248 11.5965 86.0508 11.3549C85.9768 11.1133 85.9564 10.8585 85.991 10.6083C85.9573 10.3572 85.978 10.1019 86.0519 9.85954C86.1257 9.6172 86.2509 9.39351 86.419 9.2036C86.587 9.0137 86.794 8.86202 87.026 8.75884C87.2579 8.65565 87.5094 8.60337 87.7634 8.60553C88.833 8.60553 89.4783 9.33425 89.4783 10.538V10.802H86.7638V10.8444C86.7519 10.985 86.7697 11.1266 86.8159 11.2601C86.8621 11.3935 86.9357 11.5159 87.0321 11.6193C87.1285 11.7226 87.2455 11.8048 87.3756 11.8605C87.5057 11.9162 87.646 11.9441 87.7876 11.9425C87.9691 11.9643 88.1529 11.9317 88.3158 11.8489C88.4786 11.7662 88.6131 11.637 88.7021 11.4778L89.4325 11.4778ZM86.7638 10.2425H88.7055C88.715 10.1138 88.6975 9.98458 88.654 9.86308C88.6105 9.74158 88.542 9.63051 88.4529 9.53698C88.3637 9.44346 88.256 9.36957 88.1365 9.32004C88.0171 9.27052 87.8886 9.24646 87.7592 9.24942C87.628 9.24777 87.4978 9.27234 87.3763 9.32167C87.2547 9.37099 87.1443 9.44409 87.0515 9.53664C86.9588 9.62918 86.8855 9.73931 86.8361 9.86054C86.7867 9.98176 86.7621 10.1116 86.7638 10.2425H86.7638Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_203_2753">
        <rect width="102.156" height="34.0521" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>